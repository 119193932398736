<template>
  <div class="equipment">
    <!-- 联系我们 -->
    <div class="equipment-introduce">
      <!-- <el-image
        style="width: 500px; height: 500px"
        src="https://edu.siwonet.com:443/14/bokenbanner1.jpg"
        fit="cover"
      ></el-image> -->
      <!-- 留言 -->
      <div v-if="lang == 'CN'" class="ei-box">
        <el-form ref="form" :model="form" :rules="rules">
          <div class="ei-msg">
            <div class="tips">
              {{ pageDatas.richText }}
            </div>
            <div class="em-content">
              <el-form-item>
                <el-input v-model="form.name">
                  <template slot="prepend">
                    <span>姓名</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="phone">
                <el-input v-model="form.phone">
                  <template slot="prepend">
                    <span>电话</span>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <div class="em-content">
              <el-form-item>
                <el-input v-model="form.company">
                  <template slot="prepend">
                    <span>公司</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.mail">
                  <template slot="prepend">
                    <span>邮箱</span>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <el-form-item prop="msg">
              <div class="em-leaveWord">
                <div class="el-left">
                  <span>留言</span>
                </div>
                <el-input
                  resize="none"
                  type="textarea"
                  :rows="5"
                  maxlength="200"
                  show-word-limit
                  v-model="form.msg"
                ></el-input></div
            ></el-form-item>
            <div class="em-codebox">
              <div class="submit-btn" @click="submit">
                <span>提交</span>
              </div>
            </div>
          </div>
        </el-form>
        <div class="ei-word">
          <h5 class="ei-theme">{{ pageDatas.title }}</h5>
          <p>
            <span class="pf-title"> 微信</span> ：{{ contactContent.weixin }}
          </p>
          <p>
            <span class="pf-title"> 抖音</span> ：{{ contactContent.douying }}
          </p>
          <p>
            <span class="pf-title"> 电话</span> ：{{ contactContent.phone }}
          </p>
          <p>
            <span class="pf-title"> 联系电话</span> ：{{
              contactContent.telephone
            }}
          </p>
          <p><span class="pf-title"> 邮箱</span> ：{{ contactContent.mail }}</p>
          <p>
            <span class="pf-title"> 地址</span> ：{{ contactContent.adress }}
          </p>
          <div class="qcode">
            <img :src="contactContent.codeImg1" alt="" />
            <img :src="contactContent.codeImg2" alt="" />
          </div>
        </div>
      </div>
      <div v-else class="ei-box">
        <el-form ref="form" :model="form" :rules="rules">
          <div class="ei-msg">
            <div class="tips">
              {{ pageDatas.richText }}
            </div>
            <div class="em-content">
              <el-form-item>
                <el-input v-model="form.name">
                  <template slot="prepend">
                    <span>Name</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="phoneEN">
                <el-input v-model="form.phoneEN">
                  <template slot="prepend">
                    <span>Tel</span>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <div class="em-content">
              <el-form-item>
                <el-input v-model="form.company">
                  <template slot="prepend">
                    <span>Company</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.mail">
                  <template slot="prepend">
                    <span>Mail</span>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <el-form-item prop="msgEN">
              <div class="em-leaveWord">
                <div class="el-left">
                  <span>Message</span>
                </div>
                <el-input
                  resize="none"
                  type="textarea"
                  :rows="5"
                  maxlength="200"
                  show-word-limit
                  v-model="form.msgEN"
                ></el-input></div
            ></el-form-item>
            <div class="em-codebox">
              <div class="submit-btn" @click="submit">
                <span>Submit</span>
              </div>
            </div>
          </div>
        </el-form>
        <div class="ei-word">
          <h5 class="ei-theme">{{ pageDatas.title }}</h5>
          <p>
            <span class="pf-title"> WeChat</span> ：{{ contactContent.weixin }}
          </p>
          <p>
            <span class="pf-title"> Tiktok</span> ：{{ contactContent.douying }}
          </p>
          <p><span class="pf-title"> Tel</span> ：{{ contactContent.phone }}</p>
          <p>
            <span class="pf-title"> Mobile</span> ：{{
              contactContent.telephone
            }}
          </p>
          <p><span class="pf-title"> Mail</span> ：{{ contactContent.mail }}</p>
          <p>
            <span class="pf-title"> Address</span> ：{{ contactContent.adress }}
          </p>
          <div class="qcode">
            <img :src="contactContent.codeImg1" alt="" />
            <img :src="contactContent.codeImg2" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      rules: {
        msg: [{ required: true, message: "留言不能为空", trigger: "blur" }],
        phone: [
          { required: true, message: "电话不能为空", trigger: "blur" },
          // {
          //   trigger: "blur",
          //   validator: function (rule, value, callback) {
          //     if (/^[0-9]*$/.test(value)) {
          //       callback(); // 通过
          //     } else {
          //       // 校验不通过，用new Error的形式显示错误信息
          //       callback(new Error("请输入正确的手机号"));
          //     }
          //   },
          // },
        ],
        msgEN: [
          {
            required: true,
            message: "Message cannot be empty",
            trigger: "blur",
          },
        ],
        phoneEN: [
          { required: true, message: "Phone cannot be empty", trigger: "blur" },
          // {
          //   trigger: "blur",
          //   validator: function (rule, value, callback) {
          //     if (/^1[3456789]\d{9}$/.test(value)) {
          //       callback(); // 通过
          //     } else {
          //       // 校验不通过，用new Error的形式显示错误信息
          //       callback(
          //         new Error(
          //           "Please input the correct 11 digit mobile phone number"
          //         )
          //       );
          //     }
          //   },
          // },
        ],
      },
      form: {
        name: "",
        phone: "",
        company: "",
        mail: "",
        msg: "",
        phoneEN: "",
        msgEN: "",
      },
      pageDatas: {},
      contactContent: {},
      lang: "",
    };
  },
  components: {},
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    // 校验表单是否通过 传参数需要传表单的ref的名字
    isPass(formName) {
      return new Promise((resolve) => {
        let form = this.$refs[formName];
        form.validate((pass) => {
          resolve(pass);
        });
      });
    },
    async submit() {
      //校验
      let isPass = await this.isPass("form");
      if (isPass == false) {
        return;
      }

      if (this.lang == "EN") {
        this.form.phone = this.form.phoneEN;
        this.form.msg = this.form.msgEN;
      }

      axios
        .post("http://edu.siwonet.com:8099/addLeaveWord", {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success("留言成功！");
            this.form = {
              name: "",
              phone: "",
              company: "",
              mail: "",
              msg: "",
              phoneEN: "",
              msgEN: "",
            };
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      this.lang = lang;
      axios
        .get("http://edu.siwonet.com:8099/queryTitleId", {
          params: {
            language: lang,
            titleId:
              this.nodeId[this.$route.query.titleId] ||
              this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.pageDatas = res.data.data.pageDatas[0];
            this.contactContent = JSON.parse(
              res.data.data.pageDatas[0].jsonText
            );
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  computed: {
    ...mapState(["nodeId"]),
  },
  filters: {},
};
</script>
<style>
.em-content .el-input-group {
  width: 320px;
  height: 40px;
}
.em-content .el-input__inner,
.em-codebox .el-input__inner {
  height: 40px;
}
.em-content .el-input-group__prepend {
  width: 80px;
  text-align: center;
}
.em-leaveWord .el-textarea__inner,
.em-codebox .el-input__inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
<style lang="less" scoped>
.equipment {
  width: 100%;
  display: flex;
  .equipment-introduce {
    width: 100%;
    .ei-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // 留言
      .ei-msg {
        width: 670px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .tips {
          color: #797979;
          font-size: 15px;
          margin-bottom: 40px;
        }
        .em-content {
          display: flex;
          justify-content: space-between;
        }
        .em-leaveWord {
          display: flex;
          .el-left {
            width: 100px;
            line-height: 115px;
            background-color: #f5f7fa;
            color: #909399;
            vertical-align: middle;
            display: table-cell;
            position: relative;
            border: 1px solid #dcdfe6;
            border-right: none;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding: 0 20px;
            white-space: nowrap;
            text-align: center;
          }
        }
        .em-codebox {
          display: flex;
          // width: 320px;
          .submit-btn {
            width: 100%;
            height: 50px;
            color: rgb(255, 255, 255);
            background-color: #d80930;
            text-align: center;
            line-height: 50px;
          }
          .el-left {
            width: 120px;
            line-height: 48px;
            background-color: #f5f7fa;
            color: #909399;
            vertical-align: middle;
            display: table-cell;
            position: relative;
            border: 1px solid #dcdfe6;
            border-right: none;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding: 0 20px;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
      .ei-word {
        width: 450px;
        .ei-theme {
          font-size: 24px;
          color: #3b3b3b;
          font-weight: bold;
          margin-bottom: 30px;
        }
        p {
          font-size: 15px;
          color: #3b3b3b;
          line-height: 35px;
          display: flex;

          .pf-title {
            display: block;
            min-width: 70px;
            text-align: left;
          }
        }
        .qcode {
          display: flex;
          justify-content: space-around;
          img {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}
</style>
